import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import imgProxy from "~/utils/imgproxy";
import { useEventsContext } from "~/contexts/EventsContext";
import { Show } from "solid-js";

import type { LotDrawerProps } from "~/components/Program/Components/LotDrawer";

export default function Visit3D(props: LotDrawerProps) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section class="visit-3d" data-test="3d">
        <div class="visual">
          <picture>
            <img
              src={imgProxy("/images/drawer-visit3d.jpg", "")}
              alt=""
              height="150"
              width="673"
            />
          </picture>
          <button
            type="button"
            class="btn"
            data-test="btn-3d-lot"
            onClick={() => {
              sendClick("btn-3d-lot", "drawer-dwell");
              if (
                props.lot.blueprint_3d_url.substring(
                  props.lot.blueprint_3d_url.length - 4,
                ) === ".jpg"
              ) {
                setMediaOverlay("images", props.lot.blueprint_3d_url);
              } else {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="${props.lot.blueprint_3d_url}" allowfullscreen></iframe>`,
                );
              }
            }}
          >
            <Show
              when={props.wrapper.program.field_is_cogehome3d}
              fallback="Voir le plan en 3D"
            >
              Visiter le logement
            </Show>
          </button>
        </div>
      </section>
    </>
  );
}
