import { createMemo, Show } from "solid-js";
import { getTrimesterFromDate } from "~/utils/tools";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { getPlusStr } from "~/utils/helper_lot";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";
import { formatFloor, formatSurface } from "~/utils/format";

export default function DwellDetails(props: LotDrawerWithPricesProps) {
  const settingsContext = useDrupalSettingsContext();

  const lesPlus = createMemo(() => {
    return getPlusStr(props.lot);
  });

  return (
    <>
      <section class="dwell-details" data-test="body-dwell">
        <Show
          when={
            props.wrapper.program.field_disp_grid_vat_inc ||
            props.wrapper.program.field_disp_grid_vat_inc_reduced ||
            props.wrapper.program.field_disp_grid_vat_inc_mastered ||
            props.wrapper.program.field_disp_grid_vat_inc_brs
          }
        >
          <h3>Habiter</h3>
        </Show>
        <div class="items">
          <div class="item" data-test="typology">
            <span class="label">Typologie</span>
            <span class="value">{props.lot.typology.name}</span>
          </div>
          <div class="item" data-test="surface">
            <span class="label">Surface</span>
            <span class="value">
              <Show when={props.lot.surface} fallback="-">
                {formatSurface(props.lot.surface!, 0, 2)}
              </Show>
            </span>
          </div>
          <Show when={props.lot.floor}>
            <div class="item" data-test="floor">
              <span class="label">Étage</span>
              <span
                class="value"
                innerHTML={formatFloor(props.lot.floor!, "étage")}
              />
            </div>
          </Show>
          <Show when={!props.wrapper.program.field_date_delivery_hide}>
            <div class="item" data-test="delivery">
              <Show
                when={
                  props.wrapper.program.field_program_type
                    ?.drupal_internal__tid ===
                  settingsContext.program_types.terrains
                }
                fallback={<span class="label">Livraison</span>}
              >
                <span class="label">Disponible</span>
              </Show>

              <span class="value">
                <span>À partir du </span>
                <span
                  innerHTML={getTrimesterFromDate(props.lot.date_delivery)}
                />
              </span>
            </div>
          </Show>
          <Show when={props.lot.orientation}>
            <div class="item" data-test="orientation">
              <span class="label">Orientation</span>
              <span class="value">{props.lot.orientation}</span>
            </div>
          </Show>
          <Show when={lesPlus()}>
            <div class="item" data-test="plus">
              <span class="label">Les +</span>
              <span class="value plus">{lesPlus()}</span>
            </div>
          </Show>
        </div>
      </section>
    </>
  );
}
