import { createMemo, Show } from "solid-js";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { isPrixEncadresOverride } from "~/utils/helper_program";
import { getRegulation, getSlice, hasRegulation } from "~/utils/helper_slice";

export default function Legals(props: LotDrawerWithPricesProps) {
  const settings = useDrupalSettingsContext();
  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  const legalsDrawerVatInc = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.tva_normale)
      ?.field_legals_drawer;
  });

  const legalsDrawerVatIncReduced = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.tva_reduite)
      ?.field_legals_drawer;
  });
  const legalsDrawerVatIncMastered = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.prix_maitrises)
      ?.field_legals_drawer;
  });
  const legalsDrawerVatIncBrs = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.brs)
      ?.field_legals_drawer;
  });
  const legalsDrawerPinel = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.pinel)
      ?.field_legals_drawer;
  });
  const legalsDrawerPinelPlus = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.pinel_plus)
      ?.field_legals_drawer;
  });
  const legalsDrawerPatrimonial = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.patrimonial)
      ?.field_legals_drawer;
  });
  const legalsDrawerLmnpVatInc = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc)
      ?.field_legals_drawer;
  });
  const legalsDrawerBareOwnership = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.nue_propriete)
      ?.field_legals_drawer;
  });
  const legalsDrawerLmnpVatEx = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_ex)
      ?.field_legals_drawer;
  });

  return (
    <>
      <section class="lot-drawer-legals" data-test="legals-drawer">
        <Show when={props.pricesInfos.vat_inc && legalsDrawerVatInc()}>
          <p
            innerHTML={legalsDrawerVatInc()}
            data-test="legals-drawer-vat-inc"
          />
        </Show>

        <Show
          when={
            props.pricesInfos.vat_inc_reduced && legalsDrawerVatIncReduced()
          }
        >
          <p
            innerHTML={legalsDrawerVatIncReduced()}
            data-test="legals-drawer-vat-inc-reduced"
          />
        </Show>

        <Show
          when={
            !isPrixEncadresOverride(props.wrapper) &&
            props.pricesInfos.vat_inc_mastered &&
            legalsDrawerVatIncMastered()
          }
        >
          <p
            innerHTML={legalsDrawerVatIncMastered()}
            data-test="legals-drawer-vat-inc-mastered"
          />
        </Show>

        <Show when={props.pricesInfos.vat_inc_brs && legalsDrawerVatIncBrs()}>
          <p
            innerHTML={legalsDrawerVatIncBrs()}
            data-test="legals-drawer-vat-inc-brs"
          />
        </Show>

        <Show
          when={
            props.wrapper.program.field_disp_grid_pinel &&
            props.pricesInfos.vat_inc &&
            !props.lot.is_pinel_plus &&
            hasRegulation(slice()!, settings.regulations_tids.pinel) &&
            legalsDrawerPinel()
          }
        >
          <p innerHTML={legalsDrawerPinel()} data-test="legals-drawer-pinel" />
        </Show>

        <Show
          when={
            props.wrapper.program.field_disp_grid_pinel_plus &&
            props.pricesInfos.vat_inc &&
            props.lot.is_pinel_plus &&
            hasRegulation(slice()!, settings.regulations_tids.pinel_plus) &&
            legalsDrawerPinelPlus()
          }
        >
          <p
            innerHTML={legalsDrawerPinelPlus()}
            data-test="legals-drawer-pinel-plus"
          />
        </Show>

        <Show
          when={
            props.wrapper.program.field_disp_grid_patrimonial &&
            props.pricesInfos.vat_inc &&
            hasRegulation(slice()!, settings.regulations_tids.patrimonial) &&
            legalsDrawerPatrimonial()
          }
        >
          <p
            innerHTML={legalsDrawerPatrimonial()}
            data-test="legals-drawer-patrimonial"
          />
        </Show>

        <Show
          when={
            props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
            props.pricesInfos.vat_inc &&
            hasRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc) &&
            legalsDrawerLmnpVatInc()
          }
        >
          <p
            innerHTML={legalsDrawerLmnpVatInc()}
            data-test="legals-drawer-lmnp-vat-inc"
          />
        </Show>

        <Show
          when={props.pricesInfos.bare_ownership && legalsDrawerBareOwnership()}
        >
          <p
            innerHTML={legalsDrawerBareOwnership()}
            data-test="legals-drawer-bare-ownership"
          />
        </Show>

        <Show when={props.pricesInfos.lmnp_vat_ex && legalsDrawerLmnpVatEx()}>
          <p
            innerHTML={legalsDrawerLmnpVatEx()}
            data-test="legals-drawer-lmnp-vat-ex"
          />
        </Show>

        <p class="georisques" data-test="legals-drawer-risks">
          Les informations sur les risques auxquels ce bien est exposé sont
          disponibles sur le site Géorisques&nbsp;:{" "}
          <a href="https://www.georisques.gouv.fr" target="_blank">
            www.georisques.gouv.fr
          </a>
        </p>
      </section>
    </>
  );
}
