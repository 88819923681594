import { Portal } from "solid-js/web";
import { useStackingContext } from "~/contexts/StackingContext";
import { createPreventScroll } from "@solid-aria/overlays";
import { createMemo, Match, Show, Switch } from "solid-js";
import { useLotActive } from "~/contexts/LotActiveContext";
import Heading from "~/components/Program/Components/Drawer/Heading";
import DwellDetails from "~/components/Program/Components/Drawer/DwellDetails";
import Visit3D from "~/components/Program/Components/Drawer/Visit3D";
import DwellRegulationDetails from "~/components/Program/Components/Drawer/DwellRegulationDetails";
import InvestDetails from "~/components/Program/Components/Drawer/InvestDetails";
import InvestRegulationDetails from "~/components/Program/Components/Drawer/InvestRegulationDetails";
import Autopromos from "~/components/Program/Components/Drawer/Autopromos";
import Legals from "~/components/Program/Components/Drawer/Legals";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { getSlice, hasRegulation } from "~/utils/helper_slice";
import { hasNoEscape } from "~/utils/no_escape";

import type { Lot } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./LotDrawer.css";

import IconClose from "~/img/icons/close.svg";
import {
  getPricesAndReturnRates,
  type PricesAndReturnRates,
} from "~/utils/helper_lot";

export type LotDrawerProps = {
  wrapper: ProgramWrapper;
  lot: Lot;
  isNotFromProgram?: boolean;
};

export type LotDrawerWithPricesProps = LotDrawerProps & {
  pricesInfos: PricesAndReturnRates;
};

export default function LotDrawer(props: LotDrawerProps) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { unsetLot }] = useLotActive();
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();
  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  const [
    ,
    { incrementPreventScrollLayerCount, decreasePreventScrollLayerCount },
  ] = useStackingContext();

  if (incrementPreventScrollLayerCount) {
    incrementPreventScrollLayerCount();
  }
  createPreventScroll();

  const pricesInfos = createMemo(() => {
    return getPricesAndReturnRates(props.wrapper, props.lot);
  });

  return (
    <>
      <Portal mount={document.getElementById("lot-drawer-receiver")!}>
        <div
          class="cog-overlay"
          onClick={() => {
            unsetLot!();
            decreasePreventScrollLayerCount!();
          }}
        />
        <div class="lot-drawer-modal" data-ga-zone="drawer-dwell">
          <div class="lot-drawer-content" data-test="drawer">
            {/* HEADING */}
            <section class="heading" data-test="heading">
              <button
                class="btn close-modal"
                onClick={() => {
                  unsetLot!();
                  decreasePreventScrollLayerCount!();
                }}
                type="button"
                aria-label="Fermer"
                data-test="drawer-close"
              >
                Fermer
                <i aria-hidden="true" class="cog-icon">
                  <IconClose />
                </i>
              </button>
              <Heading
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={pricesInfos()}
                isNotFromProgram={props.isNotFromProgram}
              />
              <div class="ctas">
                <button
                  type="button"
                  class="btn"
                  data-test="cta-advisor"
                  onClick={(e) => {
                    sendShowEvent(`advisor`, e, {
                      nid: props.wrapper.program.drupal_internal__nid,
                    });
                    openModalForm!("advisor", {
                      wrapper: props.wrapper,
                      lot: props.lot,
                    });
                    setModalFormHeadline!("Contacter un conseiller");
                  }}
                >
                  Contacter un conseiller
                </button>
                <Switch>
                  <Match when={props.lot.blueprint?.uri?.url}>
                    <Show
                      when={settings.form_blueprint_bypass}
                      fallback={
                        <button
                          type="button"
                          class="btn"
                          data-test="cta-blueprint"
                          onClick={(e) => {
                            sendShowEvent(`blueprint`, e, {
                              nid: props.wrapper.program.drupal_internal__nid,
                            });
                            openModalForm!("blueprint", {
                              wrapper: props.wrapper,
                              lot: props.lot,
                            });
                            setModalFormHeadline!("Télécharger le plan");
                          }}
                        >
                          Télécharger le plan
                        </button>
                      }
                    >
                      <a
                        href={props.lot.blueprint?.uri?.url}
                        class="btn"
                        data-test="btn-blueprint"
                        target="_blank"
                        onClick={() => {
                          sendClick(`btn-blueprint`, "drawer-dwell");
                        }}
                        rel="noindex nofollow"
                      >
                        Télécharger le plan
                      </a>
                    </Show>
                  </Match>
                  <Match when={props.wrapper.program.field_leaflet?.uri?.url}>
                    <Show
                      when={settings.form_leaflet_bypass}
                      fallback={
                        <button
                          type="button"
                          class="btn"
                          data-test="cta-leaflet"
                          onClick={(e) => {
                            sendShowEvent(`leaflet`, e, {
                              nid: props.wrapper.program.drupal_internal__nid,
                            });
                            openModalForm!("leaflet", {
                              wrapper: props.wrapper,
                            });
                            setModalFormHeadline!("Télécharger la brochure");
                          }}
                        >
                          Télécharger la brochure
                        </button>
                      }
                    >
                      <a
                        href={props.wrapper.program.field_leaflet?.uri?.url}
                        class="btn"
                        data-test="btn-leaflet"
                        target="_blank"
                        onClick={() => {
                          sendClick(`btn-leaflet`, "drawer-dwell");
                        }}
                        rel="noindex nofollow"
                      >
                        Télécharger la brochure
                      </a>
                    </Show>
                  </Match>
                </Switch>
              </div>
            </section>

            <section class="general-details">
              {/* DWELL DETAILS */}
              <DwellDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={pricesInfos()}
              />

              {/* VISIT 3D */}
              <Show when={props.lot.blueprint_3d_url}>
                <Visit3D wrapper={props.wrapper} lot={props.lot} />
              </Show>

              {/* DWELL REGULATIONS DETAILS */}
              <DwellRegulationDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={pricesInfos()}
              />

              {/* INVEST DETAILS */}
              <Show
                when={
                  (((props.wrapper.program.field_disp_grid_pinel &&
                    hasRegulation(slice()!, settings.regulations_tids.pinel)) ||
                    (props.wrapper.program.field_disp_grid_pinel_plus &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.pinel_plus,
                      )) ||
                    (props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.lmnp_vat_inc,
                      )) ||
                    (props.wrapper.program.field_disp_grid_patrimonial &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.patrimonial,
                      ))) &&
                    pricesInfos().vat_inc) ||
                  pricesInfos().lmnp_vat_ex ||
                  pricesInfos().bare_ownership
                }
              >
                <InvestDetails
                  wrapper={props.wrapper}
                  lot={props.lot}
                  pricesInfos={pricesInfos()}
                />
              </Show>

              {/* DISPOSITIFS DETAILS */}
              <InvestRegulationDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={pricesInfos()}
              />

              {/* INVEST LEGALS */}
              <Show
                when={
                  ((((props.wrapper.program.field_disp_grid_pinel &&
                    hasRegulation(slice()!, settings.regulations_tids.pinel)) ||
                    (props.wrapper.program.field_disp_grid_pinel_plus &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.pinel_plus,
                      )) ||
                    (props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.lmnp_vat_inc,
                      )) ||
                    (props.wrapper.program.field_disp_grid_patrimonial &&
                      hasRegulation(
                        slice()!,
                        settings.regulations_tids.patrimonial,
                      ))) &&
                    pricesInfos().vat_inc) ||
                    pricesInfos().lmnp_vat_ex ||
                    pricesInfos().bare_ownership) &&
                  props.wrapper.program.field_disp_return_rate
                }
              >
                <p class="legals" data-test="legals-return-rate">
                  * Les rentabilités indiquées sont estimatives, données à titre
                  indicatif et hors frais de gestion potentiels.
                </p>
              </Show>
            </section>

            {/* AUTOPROMOS */}
            <Show when={!hasNoEscape()}>
              <Autopromos
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={pricesInfos()}
              />
            </Show>

            {/* LEGALS */}
            <Legals
              wrapper={props.wrapper}
              lot={props.lot}
              pricesInfos={pricesInfos()}
            />
          </div>
        </div>
      </Portal>
    </>
  );
}
