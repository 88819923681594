import { Show } from "solid-js";
import { formatPrice } from "~/utils/format";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { Lot } from "~/types/drupal_jsonapi";

export default function Segments(props: { lot: Lot }) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <section class="segments" data-test="segments">
        <Show when={props.lot.field_is_access}>
          <div class="segment access" data-test="access">
            <picture>
              <img
                src={settings.marketing_segments_terms.access.field_logo_main}
                alt="Cogedim Access"
                height="20"
                width="76"
              />
            </picture>
            <p>
              <span data-test="catchline">
                Pour votre première résidence principale
              </span>{" "}
              <Show when={props.lot.field_access_mp_amount}>
                <span class="amount" data-test="amount">
                  <strong>
                    {formatPrice(props.lot.field_access_mp_amount!)}
                  </strong>{" "}
                  /mois
                </span>
              </Show>
              <span class="group">
                <span data-test="baseline">
                  avec <strong>Cogedim Access</strong>
                </span>
                <Show when={props.lot.field_access_mp_legals}>
                  <a
                    href={props.lot.field_access_mp_legals}
                    target="_blank"
                    data-test="legals"
                  >
                    Voir les conditions
                  </a>
                </Show>
              </span>
            </p>
          </div>
        </Show>
        <Show when={props.lot.field_is_invest}>
          <div class="segment invest" data-test="invest">
            <picture>
              <img
                src={settings.marketing_segments_terms.invest.field_logo_main}
                alt="Cogedim Invest"
                height="24"
                width="76"
              />
            </picture>
          </div>
        </Show>
        <Show when={props.lot.field_is_signature}>
          <div class="segment signature" data-test="signature">
            <picture>
              <img
                src={
                  settings.marketing_segments_terms.signature.field_logo_main
                }
                alt="Cogedim Signature"
                height="21"
                width="76"
              />
            </picture>
          </div>
        </Show>
      </section>
    </>
  );
}
