import { createMemo, Show } from "solid-js";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { isPrixEncadresOverride } from "~/utils/helper_program";
import { getRegulation, getSlice } from "~/utils/helper_slice";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";

export default function DwellRegulationDetails(
  props: LotDrawerWithPricesProps,
) {
  const settings = useDrupalSettingsContext();
  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  const textDrawerVatIncReduced = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.tva_reduite)
      ?.field_text_drawer?.value;
  });
  const textDrawerVatIncMastered = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.prix_maitrises)
      ?.field_text_drawer?.value;
  });
  const textDrawerVatIncBrs = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.brs)
      ?.field_text_drawer?.value;
  });

  return (
    <>
      <Show
        when={props.pricesInfos.vat_inc_reduced && textDrawerVatIncReduced()}
      >
        <section
          class="dispositif-details"
          innerHTML={textDrawerVatIncReduced()}
          data-test="regulation-text-vat-inc-reduced"
        />
        {/* Backup
        <h3>Acheter en TVA réduite</h3>
        <p>
          Sous conditions de ressources, profitez d’une TVA réduite pour
          l’achat de votre résidence principale dans le neuf.{" "}
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/une-tva-reduite-a-5-5.html",
            )}
          >
            En savoir plus
          </a>
        </p>
        */}
      </Show>

      <Show
        when={
          !isPrixEncadresOverride(props.wrapper) &&
          props.pricesInfos.vat_inc_mastered &&
          textDrawerVatIncMastered()
        }
      >
        <section
          class="dispositif-details"
          innerHTML={textDrawerVatIncMastered()}
          data-test="regulation-text-vat-inc-mastered"
        />
        {/* Backup
        <h3>Acheter en prix maîtrisés</h3>
        <p>
          Primo-accédants, et sous conditions de ressources, profitez de la
          possibilité d’acheter votre logement neuf en dessous du prix du
          marché dans une résidence éligible aux prix maîtrisés.{" "}
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/l-accession-a-prix-maitrise.html",
            )}
          >
            En savoir plus
          </a>
        </p>
        */}
      </Show>

      <Show when={props.pricesInfos.vat_inc_brs && textDrawerVatIncBrs()}>
        <section
          class="dispositif-details"
          innerHTML={textDrawerVatIncBrs()}
          data-test="regulation-text-vat-inc-brs"
        />
        {/* Backup
        <h3>Acheter en BRS</h3>
        <p>
          Avec le BRS – Bail Réel Solidaire, achetez votre résidence
          principale en-dessous des prix de marché grâce à un nouveau
          dispositif d’accession à la propriété.
        </p>
        */}
      </Show>

      <Show when={isPrixEncadresOverride(props.wrapper)}>
        <section class="dispositif-details">
          <h3>Prix encadrés pour devenir propriétaire occupants</h3>
          <p>
            Pour l’achat de votre résidence principale bénéficiez de notre
            sélection de logements à prix encadrés sous conditions d’éligibilité
            à découvrir auprès de nos conseillers commerciaux.
          </p>
        </section>
      </Show>
    </>
  );
}
